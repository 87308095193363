import { RoleEnum } from '../slices/services/api'

// FIXME: テストが増えるので機能ごとにroleのリストを作るのを辞めて、roleのリストを作ってそれを各機能で使う。
// ex) AdminAdminSalesReportManagerRole = [x, y]。

// FIXME: 画面表示に関わらない変数は別ファイルに定義する
export const AllAssignedStoresNotAvailableRoles: RoleEnum[] = [
  RoleEnum.AdminStoreManager,
  RoleEnum.Viewer,
  RoleEnum.EditorStoreManager,
]

// FIXME: 色々なページで参照されているので、各ページで1:1対応させる
export const DslUpdateRoles: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminSalesReportManager,
  RoleEnum.EditorSalesReportManager,
  RoleEnum.EditorStoreManagerInOperationCenter,
]

export const AdminAllEditorStoreManagerInOperationCenterEditorStoreManagerRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminStoreManager,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorStoreManagerInOperationCenter,
    RoleEnum.EditorStoreManager,
  ]

export const AdminAdminSalesReportManagerEditorSalesReportManagerEditorStoreManagerInOperationCenterRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorSalesReportManager,
    RoleEnum.EditorStoreManagerInOperationCenter,
  ]

export const EnableToFinalizeAndFilterByMisMatchAndSendMessageRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorSalesReportManager,
  ]

export const ShowTenantListAndSalesReportTabRoles: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminSalesReportManager,
  RoleEnum.EditorSalesReportManager,
  RoleEnum.EditorStoreManagerInOperationCenter,
]

export const MemberManagementRole: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminStoreManager,
]

export const AdminAdminSalesReportManagerEditorSalesReportManagerRoles: RoleEnum[] =
  [
    RoleEnum.Admin,
    RoleEnum.AdminSalesReportManager,
    RoleEnum.EditorSalesReportManager,
  ]

export const AdminAdminSalesReportManagerRoles: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminSalesReportManager,
]

export const ShowSalesValueTableRoles: RoleEnum[] = [
  RoleEnum.Admin,
  RoleEnum.AdminSalesReportManager,
  RoleEnum.EditorSalesReportManager,
]

export const isOneOfRoles = (availableRoles: RoleEnum[], role?: RoleEnum) => {
  return role ? availableRoles.includes(role) : false
}
